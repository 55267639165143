
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {getModule} from 'vuex-module-decorators';
    import {BookingModule, InstructionModule} from '@/store/modules';

    import {
      IAgenda,
      IOrganizationInstruction,
      IReason,
    } from '@/types';

    @Component
    export default class ReadInstructions extends Vue {
        public currentInstruction: string|null = null;
        public validatedInstructions: string[] = [];
        public endRules: boolean = false;

        @Prop({
            type: Array,
            required: true,
        })
        public preBookingInstructionsList!: IOrganizationInstruction[];

        @Prop({
            type: Object,
            required: true,
        })
        public reason!: IReason;

        @Prop({
            type: Object,
            required: true,
        })
        public agenda!: IAgenda;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private instructionModal!: boolean;

        public setCurrentInstruction() {
            if (!this.currentInstruction) {
                this.currentInstruction = this.preBookingInstructionsList[0]?.id as string;
            }
        }

        public scrollToNext(instructionNext: HTMLElement) {
            if (!instructionNext) {
                return;
            }

            instructionNext.scrollIntoView({behavior: 'smooth'});
        }

        public nextInstruction(id: string, time?: string) {
            let nextId: string | undefined = '';
            let curI: number = 0;

            this.$nextTick(() => {
                const getNextElement: HTMLElement|null = document.getElementById(id as string);
                getNextElement?.classList.add('valid');
                this.scrollToNext(getNextElement as HTMLElement);
            });

            if (id !== 'end') {
                if (this.preBookingInstructionsList.length >= 1) {
                    this.preBookingInstructionsList.forEach((elm, i) => {
                        if (elm.id === id) {
                            curI = i;
                            if (i + 1 === this.preBookingInstructionsList.length) {
                                i = i;
                            } else {
                                i = i + 1;
                            }

                            nextId = this.preBookingInstructionsList[i].id;
                        }
                    });

                    this.validatedInstructions.push(this.preBookingInstructionsList[curI].id as string);
                    this.currentInstruction = nextId;

                } else if (this.validatedInstructions.length === 0) {
                    this.validatedInstructions.push(this.preBookingInstructionsList[0].id as string);
                }

                if (this.preBookingInstructionsList.length === this.validatedInstructions.length) {
                    this.currentInstruction = null;
                    this.$nextTick(() => {
                        const getBtnEnd: HTMLElement|null = document.getElementById('btnEnd' as string);
                        this.scrollToNext(getBtnEnd as HTMLElement);
                    });

                    this.endRules = true;
                }

            } else {
                getModule(InstructionModule, this.$store)
                    .bindInstructionsAccepted(this.validatedInstructions as string[]);

                getModule(BookingModule, this.$store)
                    .handlerBookingProcess({instructions_valid: this.validatedInstructions});

                this.$emit('goToBooking');
            }
        }

        public mounted() {
            this.setCurrentInstruction();
        }
    }
