
    import Vue from 'vue';
    import {Location} from 'vue-router';
    import Component from 'vue-class-component';
    import {Prop, Watch} from 'vue-property-decorator';
    import {DateTime, Duration, ToISOTimeOptions} from 'luxon';
    import {getModule} from 'vuex-module-decorators';
    import {BookingModule, InstructionModule, RuleModule} from '@/store/modules';
    import {namespace} from 'vuex-class';

    import ReadRules from '@/components/dialogs/ReadRules.vue';
    import ReadInstructions from '@/components/dialogs/ReadInstructions.vue';
    import BlacklistedDialog from '@/components/dialogs/BlacklistedDialog.vue';

    import {
        ICheckAvailabilityParams,
        IListItem,
        IOrganization,
        IOpeningsSearchParams,
        IOpening,
        IOpeningDictionnary,
        IDailyOpenings,
        IAgenda,
        ISearchInstructionsParams,
        IReason,
        IOrganizationInstruction,
        IOrganizationRule,
        IBookingProcessing,
        IAccount,
    } from '@/types';

    const instructionNamespace = namespace('instruction');
    const ruleNamespace = namespace('rule');
    const bookingNamespace = namespace('booking');
    const accountNamespace = namespace('account');

    @Component<Openings>({
        components: {
            ReadRules,
            ReadInstructions,
            BlacklistedDialog,
        },
    })
    export default class Openings extends Vue {
        public loaded: boolean = false;
        public ignorePreselectedTime = false;
        public expanded: boolean = false;
        public changingRange: boolean = false;
        public otherReason: boolean = false;
        public hasOpenings: boolean = false;
        public hasUnavailableOpenings: boolean = false;

        public instructionModal: boolean = false;
        public url: any = null;
        public endRules: boolean = false;

        public preselectedTimeAgendas: string[] = [];
        public openings: any[] = [];
        public nextOpening: any = null;
        public from: any = null;
        public to: any = null;
        public selected: string|null = null;

        public validatedInstructions: any[] = [];
        public ruleIssue: boolean|null = null;
        public ruleModal: boolean = false;
        public validatedRules: any[] = [];
        public dialogBlacklist: boolean = false;

        public timezone!: string;
        public DATETIME_SHORT = DateTime.DATETIME_SHORT;
        public TIME_SIMPLE = DateTime.TIME_SIMPLE;

        @instructionNamespace.Getter('preBookingInstructionsList')
        public preBookingInstructionsList!: IOrganizationInstruction[];

        @ruleNamespace.Getter('rulesList')
        public rulesList!: IOrganizationRule[];

        @bookingNamespace.Getter('bookingProcess')
        public bookingProcess!: IBookingProcessing;

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount|null;

        @Prop({
            type: Object,
            required: false,
        })
        public reason!: IReason;

        @Prop({
            type: Object,
            required: false,
        })
        public blacklisted!: any;

        @Prop({
            type: Object,
            required: false,
        })
        public specialty!: IListItem;

        @Prop({
            type: Object,
            required: false,
        })
        public species!: IListItem;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public temporaryReason!: boolean;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        @Prop({
            type: Object,
            required: false,
        })
        public agenda!: IAgenda;

        @Prop({
            type: Object,
            required: false,
        })
        public preselectedTime!: DateTime;

        @Prop({
            type: String,
            required: false,
            default: 'link',
        })
        public mode!: string;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public hover!: boolean;

        @Prop({
            type: Boolean,
            required: false,
            default: false,
        })
        public disabled!: boolean;

        @Watch('reason')
        public onReasonChange() {
            if (!this.disabled) {
                this.ignorePreselectedTime = false;
                this.loadOpenings(this.from, this.to);
            }
        }

        @Watch('species')
        public onSpeciesChange() {
            if (!this.disabled) {
                this.ignorePreselectedTime = false;
                this.loadOpenings(this.from, this.to);
            }
        }

        @Watch('agenda')
        public onAgendaChange(newValue: IAgenda|null, oldValue: IAgenda|null) {
            if (this.disabled) {
                return;
            }

            if (newValue !== oldValue) {
                this.ignorePreselectedTime = false;
                this.loadOpenings(this.from, this.to);
            }
        }

        // handle changes
        get maxOpeningsCount() {
            return this.openings.reduce((max, day) => {
                if (max === null || Object.keys(day.openings).length > max) {
                    return Object.keys(day.openings).length;
                }

                return max;
            }, null);
        }

        get openingsShown() {
            return this.expanded ? this.maxOpeningsCount : 2;
        }

        get filteredOpenings() {
            const filteredData = this.openings.map(day => {
                // Trie les ouverture du jour / date.
                const sortedOpenings = Object.fromEntries(
                    Object.entries(day.openings).sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime())
                );

                // Ajout de placeholders / nb d'ouverture.
                const placeholdersNeeded = Math.max(0, this.openingsShown - Object.keys(sortedOpenings).length);
                for (let i = 0; i < placeholdersNeeded; i++) {
                    sortedOpenings[`placeholder-${i}`] = [];
                }

                return { date: day.date, openings: sortedOpenings };
            });

            return filteredData;
        }

        get buttonProps() {
            if (this.hover) {
                return {
                    color: 'secondary',
                    class: 'black--text button-slot',
                };
            }

            return {
                color: 'primary',
                class: 'button-slot',
            };
        }

        get showPreselectedTime() {
            return this.checkPreselectedTime && this.preselectedTimeAgendas.length > 0;
        }

        get checkPreselectedTime() {
            return this.preselectedTime && !this.ignorePreselectedTime;
        }

        get status() {
            if (!this.loaded) {
                return 'loading';
            }

            if (this.showPreselectedTime) {
                return 'showPreselectedTime';
            }

            if (this.changingRange || this.disabled || this.nextOpening || this.hasOpenings) {
                return 'hasOpenings';
            }

            if (this.otherReason) {
                return 'hasOtherReason';
            }

            if (this.from !== 'now') {
                return 'hasPreviousOpenings';
            }

            if (this.hasUnavailableOpenings) {
                return 'hasUnavailableOpenings';
            }

            return 'contact';
        }

        public handlerIssue(rule: any) {
            this.$emit('ruleIssue', rule);
        }

        public handleClick(target: string, time?: any, agendas?: any) {
            let agenda;

            if (target === 'getBookingLink') {
                const randomAgendaIndex = Math.floor(Math.random() * agendas.length);

                this.url = this.getBookingLink(time, [agendas[randomAgendaIndex]]);
                agenda = {id: agendas[randomAgendaIndex]};
            } else {
                this.url = this.getPreselectedTimeLink();
                agenda = {id: this.preselectedTimeAgendas[0]};
            }

            const params = {
                agenda,
                start: time,
                reason: this.reason,
                instructions_valid:  [],
                rules_valid: [],
            };

            const bookingModule = getModule(BookingModule, this.$store);

            bookingModule
                .resetBookingProcess()
                .then(() => {
                    bookingModule
                        .handlerBookingProcess(params)
                        .then(() => {
                            if (this.temporaryReason) {
                                return this.$router.push(this.url);
                            }

                            this.fetchRules();
                        })
                    ;
                })
            ;
        }

        public fetchRules() {
            if (this.blacklisted?.status === 'blacklisted') {
                this.dialogBlacklist = true;
                return;
            }

            if (this.loggedAccount?.status === 'blacklisted') {
                this.dialogBlacklist = true;
                return;
            }

            if (!this.organization) {
                return this.goToBooking();
            }

            this.ruleIssue = null;
            const ruleModule = getModule(RuleModule, this.$store);

            ruleModule
                .fetchRules(
                    {
                        organization_id: this.organization.id as string,
                        reason_id: this.reason.id as string,
                        status: 'enabled',
                    },
                )
                .then(() => {
                    if (this.rulesList.length > 0) {
                        this.ruleModal = true;
                    } else {
                        this.ruleModal = false;
                        this.fetchInstructions();
                    }
                },
            );
        }

        public fetchInstructions() {
            const instructionModule = getModule(InstructionModule, this.$store);

            const paramsEnabled: ISearchInstructionsParams = {
                page: 1,
                page_size: 500,
                organization_id: (this.organization as IOrganization).id as string,
                title: '',
                status: 'enabled',
                agenda_id: (this.agenda as IAgenda).id as string,
                reason_id: (this.reason as IReason).id as string,
            };

            instructionModule
                .fetchInstructions(paramsEnabled)
                .then(() => {
                    if (this.preBookingInstructionsList.length > 0) {
                        this.instructionModal = true;
                    } else {
                        this.$router.push(this.url);
                    }
                },
            );
        }

        public goToBooking() {
            if (this.mode === 'emit') {
              return this.$emit(
                  'selected',
                  {
                    time: this.bookingProcess.start,
                    agendas: [this.bookingProcess.agenda],
                  },
              );
            }

            this.$router.push(this.url);
        }

        public getPreselectedTimeLink(): Location {
            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
            };

            let name = 'step-details';

            const query: any = {
                is_temporary_reason: '0',
                agenda_id: this.preselectedTimeAgendas[0],
                time: this.preselectedTime.toUTC().toISO(formatOpts),
            };

            if (this.reason) {
                query.reason_id = this.reason.id;
            }

            if (this.species) {
                query.species_id = this.species.id;
            }

            if (query.is_temporary_reason === '0') {
                name = 'step-animal';
            }

            return {
                name,
                query,
            };
        }

        public getBookingLink(time: string, agendas: string[]): Location {
            const query: any = {
                is_temporary_reason: this.temporaryReason ? '1' : '0',
                agenda_id: agendas[0],
                time,
            };

            let name = 'step-details';

            if (this.reason) {
                query.reason_id = this.reason.id;
            }

            if (this.species) {
                query.species_id = this.species.id;
            }

            if (query.is_temporary_reason === '0') {
                name = 'step-animal';
            }

            return {
                name,
                query,
            };
        }

        public showOpenings() {
            this.ignorePreselectedTime = true;
            this.loadOpenings(this.from, this.to);
        }

        public loadPreviousOpenings() {
            const fromAsDay = (this.from as DateTime).toLocal().toISODate();

            if (fromAsDay) {
                let to = DateTime.fromISO(fromAsDay).minus({day: 1}).endOf('day').toUTC();
                let from: any = DateTime.fromISO(fromAsDay).minus({days: 3}).startOf('day').toUTC();
                if (from < DateTime.utc()) {
                    from = 'now';
                    to = DateTime.now().plus({days: 2}).endOf('day').toUTC();
                }

                this.loadOpenings(from, to);
            }


            
        }

        public loadNextOpenings() {
            const toAsDay = (this.to as DateTime).toLocal().toISODate();

            if (toAsDay) {
                const from = DateTime.fromISO(toAsDay).plus({day: 1}).startOf('day').toUTC();
                const to = DateTime.fromISO(toAsDay).plus({days: 3}).endOf('day').toUTC();

                this.loadOpenings(from, to);
            }
        }

        public loadNextOpening() {
            const nextOpeningAsDay = DateTime.fromISO(this.nextOpening).toISODate();

            if (nextOpeningAsDay) {
                const from = DateTime.fromISO(nextOpeningAsDay).startOf('day').toUTC();
                const to = DateTime.fromISO(nextOpeningAsDay).plus({days: 2}).endOf('day').toUTC();

                this.loadOpenings(from, to);
            }
        }

        public loadOpenings(from: DateTime|string, to: DateTime) {
            this.expanded = false;
            this.from = from;
            this.to = to;

            if (this.checkPreselectedTime) {
                this.loaded = false;

                this.$api.openings
                    .checkAvailability(this.getCheckAvailabilityParams())
                    .then((agendasIds: string[]) => {
                        this.preselectedTimeAgendas = agendasIds;

                        if (agendasIds.length === 0) {
                            this.ignorePreselectedTime = true;
                            this.loadOpenings(from, to);
                        }
                    })
                    .finally(() => this.loaded = true)
                ;

                return;
            }

            this.changingRange = true;

            this.$api.openings
                .doSearch(this.getParams())
                .then((response) => {
                    this.otherReason = response.other_reason;
                    this.hasUnavailableOpenings = response.has_unavailable_openings;
                    this.nextOpening = response.next_opening;
                    this.hasOpenings = response.openings.length > 0;
                    this.openings = [];

                    [2, 1, 0].forEach((el) => {
                        const toAsDay = (this.to as DateTime).toLocal().toISODate();

                        if (!toAsDay) return;
                        const day = DateTime.fromISO(toAsDay).minus({days: el});
                        const toISOOpts: ToISOTimeOptions = {
                            suppressMilliseconds: true,
                        };
                    
                        this.openings.push({
                            date: day.toISO(toISOOpts),
                            openings: response.openings
                                .filter((val) => {
                                    return DateTime.fromISO(val.start).toISODate() === day.toISODate();
                                })
                                .reduce((carry: IOpeningDictionnary, opening: IOpening) => {
                                    const now = DateTime.now();
                                    const duration = opening.duration ?? response.duration;
                                    const interval = opening.interval ?? response.interval ?? { min: 3_600_000, max: 7_776_000_000 };
                                    const minDuration = Duration.fromMillis(interval.min);
                                    const maxDuration = Duration.fromMillis(interval.max);
                                    const start = DateTime.fromISO(opening.start).toLocal();
                                    const end = DateTime.fromISO(opening.end).toLocal() <= now.plus(maxDuration) ?
                                        DateTime.fromISO(opening.end).toLocal() :
                                        now.plus(maxDuration)
                                    ;
                                    const times = [];

                                    let cursor = start.set({ second: 0, millisecond: 0 });

                                    if (start < now.plus(minDuration)) {
                                        const tmpStart = now.plus(minDuration);
                                        while (cursor < tmpStart) {
                                            cursor = cursor.plus({ minutes: duration });
                                        }
                                    }

                                    while (cursor < end) {
                                        times.push(cursor.toUTC().toISO(toISOOpts));
                                        cursor = cursor.plus({ minutes: duration });
                                    }

                                    const startDate = new Date(opening.start);
                                    const endDate = new Date(opening.end);
                                    let diff =(endDate.getTime() - startDate.getTime()) / 1000;
                                    diff /= (60 * 60);
                                    const nbHours =  Math.abs(Math.round(diff));
                                    if (duration) {
                                        const nbSlots = Math.floor((nbHours*60) / duration);

                                        times.forEach((time, i) => {
                                            if (time == null) return;
                                            if (!carry[time]) {
                                                carry[time] = [];
                                            }
                                            if(i<=nbSlots) {
                                                carry[time].push(opening.agenda_id);
                                            }
                                        })
                                    }
                                    return carry;
                                }, {}),
                        });
                        
                    });
                    
                    this.changingRange = false;
                    this.loaded = true;
                });
        }

        private getParams(): IOpeningsSearchParams {
            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
            };

            return {
                from: this.from === 'now' ? 'now' : this.from.set({milliseconds: 0}).toISO(formatOpts),
                to: this.to.set({milliseconds: 0}).toISO(formatOpts),
                agenda_id: this.agenda ? this.agenda.id : null,
                organization_id: this.organization ? (this.organization.id as string) : null,
                reason_id: this.reason ? this.reason.id as string : null,
                specialty_id: this.specialty ? this.specialty.id as string : null,
                species_id: this.species ? this.species.id as string : null,
            };
        }

        private getCheckAvailabilityParams(): ICheckAvailabilityParams {
            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
            };

            return {
                time: this.preselectedTime.toUTC().toISO(formatOpts),
                agenda_id: this.agenda ? this.agenda.id : null,
                organization_id: this.organization ? (this.organization.id as string) : null,
                reason_id: this.reason.id as string,
                species_id: this.species ? this.species.id as string : null,
            };
        }

        private setPlaceholderData() {
            this.from = 'now';
            this.to = DateTime.now().plus({days: 2}).endOf('day').toUTC();

            const formatOpts: ToISOTimeOptions = {
                suppressMilliseconds: true,
            };

            [2, 1, 0].forEach((el) => {
                const day = (this.to as DateTime).minus({days: el}).toLocal();

                this.openings.push({
                    date: day.toISO(formatOpts),
                    openings: [],
                });
            });

            this.loaded = true;
        }

        private mounted() {

            if (this.organization) {
                this.timezone = this.organization.timezone;
            } else if (this.agenda && this.agenda.organization) {
                this.timezone = this.agenda.organization?.timezone;
            }

            if (this.disabled) {
                this.setPlaceholderData();
                return;
            }

            this.loadOpenings('now', DateTime.now().plus({days: 2}).endOf('day').toUTC());

        }
    }
