
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, PropSync} from 'vue-property-decorator';
    import {getModule} from 'vuex-module-decorators';
    import {BookingModule, RuleModule} from '@/store/modules';

    import {
        IOrganizationRule,
        IOrganization,
    } from '@/types';

    @Component
    export default class ReadRules extends Vue {
        public currentRule: string|null = null;
        public validatedRules: Array<string> = [];
        public isWaiting: boolean = true;
        public isWaitingIndex: number = 3;
        public resInterval: number | null = null;
        public resTimeout: number | null = null;
        public ruleIssue: boolean|null = null;


        @Prop({
            type: Array,
            required: true,
        })
        public rulesList!: IOrganizationRule[];

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private ruleModal!: boolean;

        public getCurrentRule() {
            this.handlerWaiting();

            if (!this.currentRule) {
                this.currentRule = this.rulesList[0]?.id as string;
            }
        }

        public handlerWaiting() {
            this.isWaitingIndex = 2;
            this.isWaiting = true;
            clearInterval(this.resInterval as number);
            clearInterval(this.resTimeout as number);

            this.resInterval = setInterval(() => {
                if (this.isWaitingIndex > 0) {
                    this.isWaitingIndex = this.isWaitingIndex - 1;
                }
            }, 1000);

            this.resTimeout = setTimeout(() => {
                this.isWaiting = false;
            }, 2000);

        }

        public handlerRuleIssue(rule: IOrganizationRule) {
            this.ruleModal = false;
            this.ruleIssue = null;
            this.currentRule = null;
            this.$emit('handlerIssue', rule);
        }

        public nextRule(rule: IOrganizationRule, response: boolean) {
            let curI: number = 0;

            this.handlerWaiting();

            if (!response) {
                this.ruleIssue = !!rule.organization_reason_issue_id;
            } else if (this.rulesList.length > 1) {
                this.currentRule = this.rulesList[curI + 1].id;
            }

            if (response && this.rulesList.length !== this.validatedRules.length) {
                const isValidated = (elm: IOrganizationRule) => elm.rule_id === rule.rule_id;
                curI = this.rulesList.findIndex(isValidated);

                if (curI !== -1) {
                    this.validatedRules.push(this.rulesList[curI].id);
                }
            }

            if (this.rulesList.length === this.validatedRules.length) {
                this.ruleModal = false;
                this.currentRule = null;

                getModule(BookingModule, this.$store)
                    .handlerBookingProcess({rules_valid: this.validatedRules});

                getModule(RuleModule, this.$store)
                    .bindRulesAccepted(this.validatedRules as Array<string>);

                this.$emit('fetchInstructions');
            }
        }

        public mounted() {
            this.getCurrentRule();
        }
    }
