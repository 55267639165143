
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {PropSync} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';

    import {
        IAccount,
    } from '@/types';

    const accountNamespace = namespace('account');

    @Component
    export default class BlacklistedDialog extends Vue {

        @accountNamespace.Getter('loggedAccount')
        public loggedAccount!: IAccount|null;

        @PropSync('opened', {
            type: Boolean,
            default: () => false,
        })
        private dialogBlacklist!: boolean;

    }
